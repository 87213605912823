<template>
    <div class="mainBox">
        <div class="home-wrapper">
            <div class="billboard-module">
                <div class="billboard-slot billboard-slot-1 ">
                    <router-link :to="{path:'/shop'}">
            <span class="billboard-slot-image pcImg">
              <img :src="logo">
            </span>
                        <span class="billboard-slot-image phoneImg">
              <img
                  :src="portrait">
            </span>
                        <div class="slot-text-container">
              <span class="billboard-slot-text ">
                <span class="text">BING XU 2021 Collection</span>
              </span>
                            <span class="billboard-slot-link ">
                <span class="text"></span>
              </span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import homeLogo from "@/assets/home.jpg";
import portrait from "@/assets/portrait.jpg";

export default {
    name: "home",
    comments: {},
    mounted() {
    },
    data() {
        return {
            logo: homeLogo,
            portrait: portrait
        };
    }
};
</script>
<style scoped lang="scss">
.mainBox {
    .billboard-slot {
        margin-bottom: 4.1875rem;

        a {
            padding-bottom: .125rem;
            color: inherit;
            position: relative;
            text-decoration: none;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: transparent;

            .billboard-slot-image {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .slot-text-container {
            display: flex;
            flex-direction: column;
        }
    }

}

@media (min-width: 768px) {
    .mainBox {
        min-height: calc(100vh - 5rem);
        margin-bottom: 9.375rem;
    }

}

@media (min-width: 1280px) {
    .mainBox {
        min-height: calc(100vh - 6.875rem);
        margin-bottom: 5.625rem;

        .billboard-module {
            margin: 0 13%;
        }
    }
    .billboard-slot-image {
        margin-bottom: 1.25rem;
    }
    .pcImg {
        display: block;
    }
    .phoneImg {
        display: none;
    }
}

@media (max-width: 1280px) {
    .mainBox {
        margin-top: 3.125rem;
    }
    .pcImg {
        display: none;
    }
    .phoneImg {
        display: block;
    }
    .billboard-slot-image {
        margin-bottom: .375rem;
    }
    .billboard-slot {
        margin: 0 1.875rem 2.875rem;
    }

}
</style>
